<template>
  <svg width="45" height="40" viewBox="0 0 45 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7367:5311)">
      <path
        opacity="0.4"
        d="M44.3904 26.2751C43.365 24.8835 41.4109 24.5832 40.0206 25.6135L30.6713 32.5019H21.2484C20.565 32.5019 19.9986 31.9355 19.9986 31.2525C19.9986 30.5689 20.565 30.0025 21.2484 30.0025H27.3615C28.6113 30.0025 29.7637 29.1529 29.9686 27.923C30.2225 26.3605 29.0215 25.0031 27.4982 25.0031H14.9989C12.8897 25.0031 10.8488 25.7258 9.20836 27.0539L5.5757 30.0025H1.24984C0.566406 30.0025 0 30.5689 0 31.2525V38.7507C0 39.4343 0.566406 40.0007 1.24984 40.0007H28.3576C30.0786 40.0007 31.7887 39.4391 33.1778 38.4169L43.7288 30.6422C45.118 29.6192 45.4145 27.6643 44.3904 26.2751Z"
        fill="#FF69AC"
      />
      <path
        opacity="0.4"
        d="M26.9709 8.74996C26.9709 8.00588 26.3681 7.40381 25.6247 7.40381H20.2503C19.7118 8.20586 19.4034 9.12832 19.2962 10.0961H25.6247C26.3681 10.0961 26.9709 9.49405 26.9709 8.74996ZM14.8555 12.7884C14.8555 13.5325 15.4582 14.1346 16.2016 14.1346H16.4907C16.1708 13.2973 15.9707 12.414 15.9008 11.5029C15.3068 11.6428 14.8555 12.1512 14.8555 12.7884ZM25.6247 11.4423H19.2962C19.4034 12.4101 19.7116 13.3325 20.2499 14.1346H25.6247C26.3681 14.1346 26.9709 13.5325 26.9709 12.7884C26.9709 12.0443 26.3681 11.4423 25.6247 11.4423ZM14.8555 8.74996C14.8555 9.3872 15.3068 9.89546 15.9008 10.0354C15.9707 9.12436 16.1708 8.24112 16.4908 7.40381H16.2016C15.4582 7.40381 14.8555 8.00588 14.8555 8.74996Z"
        fill="#FF69AC"
      />
      <path
        d="M28.6136 17.962C28.8147 18.869 28.2422 19.7682 27.3351 19.9693C26.6483 20.1205 25.9574 20.1954 25.2725 20.1954C22.8024 20.1954 20.4177 19.2253 18.6246 17.4322C16.8453 15.6535 15.8652 13.2872 15.8652 10.7698C15.8652 8.25228 16.8453 5.88599 18.6252 4.10735C20.9146 1.81598 24.1689 0.874725 27.3351 1.57014C28.2422 1.77131 28.8147 2.67049 28.6136 3.57755C28.4131 4.48465 27.5199 5.05121 26.6069 4.85665C24.5705 4.40312 22.4777 5.0131 21.0053 6.48676C19.8603 7.63048 19.2306 9.15147 19.2306 10.7698C19.2306 12.388 19.8603 13.909 21.0047 15.0527C22.4764 16.5251 24.5686 17.135 26.6069 16.6828C27.5113 16.4843 28.4131 17.0549 28.6136 17.962Z"
        fill="#FF69AC"
      />
      <path
        opacity="0.4"
        d="M27.1251 8.74996C27.1251 8.00588 26.5224 7.40381 25.779 7.40381H20.4046C19.8661 8.20586 19.5577 9.12832 19.4505 10.0961H25.779C26.5224 10.0961 27.1251 9.49405 27.1251 8.74996ZM15.0098 12.7884C15.0098 13.5325 15.6125 14.1346 16.3559 14.1346H16.645C16.3251 13.2973 16.125 12.414 16.0551 11.5029C15.4611 11.6428 15.0098 12.1512 15.0098 12.7884ZM25.779 11.4423H19.4505C19.5577 12.4101 19.8659 13.3325 20.4042 14.1346H25.779C26.5224 14.1346 27.1251 13.5325 27.1251 12.7884C27.1251 12.0443 26.5224 11.4423 25.779 11.4423ZM15.0098 8.74996C15.0098 9.3872 15.4611 9.89546 16.0551 10.0354C16.125 9.12436 16.3251 8.24112 16.645 7.40381H16.3559C15.6125 7.40381 15.0098 8.00588 15.0098 8.74996Z"
        fill="#FF69AC"
      />
      <path
        d="M28.7679 17.962C28.969 18.869 28.3965 19.7682 27.4894 19.9693C26.8026 20.1205 26.1117 20.1954 25.4268 20.1954C22.9567 20.1954 20.572 19.2253 18.7789 17.4322C16.9996 15.6535 16.0195 13.2872 16.0195 10.7698C16.0195 8.25228 16.9996 5.88599 18.7795 4.10735C21.0689 1.81598 24.3232 0.874725 27.4894 1.57014C28.3965 1.77131 28.969 2.67049 28.7679 3.57755C28.5674 4.48465 27.6742 5.05121 26.7612 4.85665C24.7248 4.40312 22.632 5.0131 21.1596 6.48676C20.0146 7.63048 19.3849 9.15147 19.3849 10.7698C19.3849 12.388 20.0146 13.909 21.159 15.0527C22.6307 16.5251 24.7229 17.135 26.7612 16.6828C27.6656 16.4843 28.5674 17.0549 28.7679 17.962Z"
        fill="#FF69AC"
      />
    </g>
    <defs>
      <clipPath id="clip0_7367:5311">
        <rect width="45" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
