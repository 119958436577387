import { defineStore } from 'pinia'

export const usePartnerStore = defineStore('home-partner', {
  state: () => ({
    image: null,
    assurance_pno: true,
    garantie_loyers_impayes: true,
    expert_comptable: true,
  }),
  actions: {
    async getPartner(name) {
      const partnerApi = usePartnerApi()
      const partner = await partnerApi.getPartner(name)
      if (!!partner && partner.length) {
        this.image = partner[0].image
        this.assurance_pno = partner[0].avantages.assurance_pno
        this.garantie_loyers_impayes = partner[0].avantages.garantie_loyers_impayes
        this.expert_comptable = partner[0].avantages.expert_comptable
      }
    },
  }
})