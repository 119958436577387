<template>
  <Section class="benefits-section flex-col h-center no-space">
    <div class="benefits-container flex-col h-center">
      <div class="benefits-subcontainer flex-col h-center">
        <div v-if="variant === 'offers'" class="header-container flex-col">
          <div class="header-picture-title">
            <h2 class="title">Nos partenaires vous font <strong>profiter d’avantages exclusifs</strong></h2>
          </div>
          <Offers-ComponentsInformation class="information" />
        </div>
        <Offers-ComponentsBenefitsCardContainer
          class="benefits-card-container"
          :cardsData="benefits_cards"
        />
      </div>
    </div>
  </Section>
</template>

<script>
import { LMNP_PARTNER_URL, BENEFIT_GLI, GLI_PARTNER_URL, PNO_UNDER_110_SQUARE_METERS, PNO_PARTNER_URL } from '@/config/config'

export default {
  props: {
    assurance_pno: { type: Boolean, required: true },
    garantie_loyers_impayes: { type: Boolean, required: true },
    expert_comptable: { type: Boolean, required: true },
    variant: { type: String, default: 'offers' },
  },
  computed: {
    benefits_cards() {
      const benefits_cards = []
      if (this.assurance_pno)
        benefits_cards.push({
          title: 'Assurance Propriétaire Non Occupant',
          pricePerYear: PNO_UNDER_110_SQUARE_METERS,
          subdescription: 'Pour un appartement de moins de 200m2',
          link: PNO_PARTNER_URL,
          img: '~/assets/imgs/shield-with-check.svg',
          color: 'orange',
          list: [
            'Assurance obligatoire',
            'Sans franchise',
            'Mobilier assuré jusqu’à 5 000€ (option)',
            'Déductible des revenus fonciers',
          ],
        })
      if (this.garantie_loyers_impayes)
        benefits_cards.push({
          title: 'Garantie Loyers Impayés',
          percent: BENEFIT_GLI,
          subdescription: 'du montant de votre loyer',
          link: GLI_PARTNER_URL,
          img: '~/assets/imgs/hand-holding-euro.svg',
          color: 'pink',
          list: [
            'Protection juridique',
            'Remboursement intégral des impayés',
            'Détérioration immobilière assurée',
            'Démarches administratives assistées',
          ],
        })
      if (this.expert_comptable)
        benefits_cards.push({
          title: 'Votre expert comptable',
          price: 280,
          link: LMNP_PARTNER_URL,
          img: '~/assets/imgs/piggy-bank.svg',
          color: 'blue',
          list: [
            'Expert de la déclaration en LMNP',
            'Stratégie et optimisations fiscales',
            'Professionnel joignable par mail et téléphone',
            'Une personne dédiée à votre dossier',
          ],
        })
      return benefits_cards
    },
  },
}
</script>

<style lang="less" scoped>
.benefits-section {
  position: relative;

  &.sectionIsVisible {
    .information {
      opacity: 1;
      transform: translateX(0) scale(1);
      transition: all 750ms ease;
      will-change: transform;
    }
  }
}

.benefits-plus-icon {
  height: 95px;
}

.benefits-subcontainer {
  overflow: hidden;
  width: 100%;
}

.header-container {
  width: 100%;

  .header-picture-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    margin: 0;
    font-weight: var(--ds-weight-semi-bold);
    font-size: 22px;
    text-align: center;

    strong {
      color: var(--ds-color-primary-100);
      font-weight: inherit;
    }
  }

  .information {
    width: 100%;

    opacity: 0;
    transform: translateX(100%) scale(0.2);
    transition: all 750ms ease;
    will-change: transform;
  }
}

.benefits-card-container {
  margin-top: 50px;
}

@media @bp-desktop {
  .header-container {
    .header-picture-title {
      display: flex;
    }
  }

  .benefits-container {
    position: relative;
  }

  .benefits-subcontainer {
    .header-container {
      position: relative;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80px;

      .title {
        font-size: 34px;
        text-align: left;
        display: inline-block;
      }

      .information {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
}

@media @bp-desktop-xl {
  .benefits-section {
    max-width: 100% !important;
    height: auto !important;
  }
}
</style>
