<template>
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.4608 2.0322L5.84172 9.65125C5.60919 9.88375 5.30461 10 5 10C4.69538 10 4.39083 9.88375 4.1583 9.65125L0.348773 5.84172C-0.116258 5.37669 -0.116258 4.62333 0.348773 4.1583C0.813803 3.69327 1.56717 3.69327 2.03217 4.1583L5 7.12613L11.7774 0.348773C12.2424 -0.116258 12.9957 -0.116258 13.4607 0.348773C13.9257 0.813803 13.9258 1.56717 13.4608 2.0322Z"
    />
  </svg>
</template>

<style lang="less" scoped>
path {
  fill: #272727;
}
</style>
