import { horizFetch } from '../core/api'

export function usePartnerApi() {
  return {
    getPartner: (name) =>
      horizFetch('/partnership/partner', {
        query: {
          name,
        },
      }),
  }
}
