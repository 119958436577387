<template>
  <svg width="12" height="30" viewBox="0 0 12 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.37695 4.68799C9.37695 6.75904 7.69801 8.43799 5.62695 8.43799C3.5559 8.43799 1.87695 6.75904 1.87695 4.68799C1.87695 2.61682 3.5559 0.937988 5.62695 0.937988C7.69801 0.937988 9.37695 2.61682 9.37695 4.68799Z"
      fill="white"
    />
    <path
      d="M11.252 27.1932C11.252 28.7431 9.99312 30.0005 8.43945 30.0005H2.81445C1.26078 30.0005 0.00195312 28.7431 0.00195312 27.1932C0.00195312 25.6433 1.26078 24.3859 2.81445 24.3859H2.81709L2.78826 16.8651C1.23084 16.845 -0.0171484 15.5729 0.0021875 14.023C0.0214063 12.4842 1.28035 11.2505 2.82225 11.2505H2.85805L5.67904 11.2852C7.22352 11.3053 8.46512 12.5554 8.46512 14.0925V24.3886C10.0065 24.4026 11.252 25.6521 11.252 27.1932Z"
      fill="white"
    />
  </svg>
</template>
