<template>
  <div class="cards-container flex-col">
    <div ref="scroll-container" class="slider flex-row">
      <Offers-ComponentsBenefitsCard
        v-for="(cardData, index) in cardsData"
        :key="cardIndex(index)"
        :cardData="cardData"
        :id="cardIndex(index)"
        class="card"
      />
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      cardsScrollNode: null,
      currentHoveredCard: 'card-0',
    }
  },
  props: {
    cardsData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    cardIndex(index) {
      return 'card-' + index
    },
    manageCardsScroll() {
      let elements = []
      let previousElemPos = 0
      const scrollWidth = this.cardsScrollNode.scrollWidth
      const scrollPercentage =
        (this.cardsScrollNode.scrollLeft /
          (this.cardsScrollNode.scrollWidth - this.cardsScrollNode.offsetWidth)) *
        100

      this.cardsScrollNode.childNodes.forEach((value, index) => {
        const elemPosition = value.offsetLeft + value.offsetWidth

        elements.push({
          id: value.id,
          onScreenPixels: elemPosition,
          startPercent: (previousElemPos / scrollWidth) * 100,
          endPercent: (elemPosition / scrollWidth) * 100,
        })

        previousElemPos = elemPosition
      })

      let element = elements.find(elem => scrollPercentage >= elem.startPercent && scrollPercentage <= elem.endPercent)

      if (element === undefined) element = elements.at(-1)

      if (this.currentHoveredCard !== element.id) this.currentHoveredCard = element.id
    },
  },
  mounted() {
    this.cardsScrollNode = this.$refs['scroll-container']
    this.cardsScrollNode.addEventListener('scroll', this.manageCardsScroll)
  },
  beforeDestroy() {
    this.cardsScrollNode.removeEventListener('scroll', this.manageCardsScroll)
  }
}
</script>

<style lang="less" scoped>
.cards-container {
  width: 100%;
}

.slider {
  overflow: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 381px);
  grid-gap: 32px;
  max-width: 1240px;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media @bp-desktop {
  .slider {
    margin: auto;

    &::-webkit-scrollbar {
      display: none;
      height: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background: #cccccc;
      border-radius: 17px;
    }

    &::-webkit-scrollbar-track {
      background-color: #eeeeee;
      border-radius: 17px;
    }

    & {
      -ms-overflow-style: auto;
      scrollbar-width: auto;
    }
  }
}
</style>
