<template>
  <div class="information flex-row h-right">
    <div class="information-subcontainer flex-row v-center">
      <Offers-ComponentsInformationLetterIcon class="information-icon" />
      <span class="text">
        Disponible uniquement pour nos abonnés
        <br class="hide-on-desktop" />
        ayant la formule
        <span class="bold">avec engagement</span>
      </span>
    </div>
  </div>
</template>

<style lang="less" scoped>
.information {
  .information-subcontainer {
    background-color: var(--ds-color-primary-25);
    border-radius: 25px 0 0 25px;
    height: 58px;

    .information-icon {
      height: 30px;
      margin-left: 24px;
    }

    .text {
      color: var(--ds-color-white-100);
      font-weight: var(--ds-weight-semi-bold);
      font-size: 16px;
      margin-left: 14px;
      margin-right: 60px;
      white-space: nowrap;

      .bold {
        font-weight: 800;
      }
    }
  }

  @media @bp-desktop {
    .information-subcontainer {
      height: 80px;
      position: relative;
      top: -20px;
      .information-icon {
        margin-left: 33px;
      }

      .text {
        font-size: 18px;
        margin-left: 16px;
      }
    }
  }
}
</style>
