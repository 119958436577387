<template>
  <div class="card flex-col">
    <div class="title-container">
      <h3 class="title">{{ cardData.title }}</h3>
    </div>

    <div class="price-container">
      <div class="icon-container">
        <Offers-ComponentsShieldWithCheckIcon
          v-if="cardData.color == 'orange'"
          class="icon"
        />
        <Offers-ComponentsPiggyBankIcon
          v-else-if="cardData.color == 'blue'"
          class="icon"
        />
        <Offers-ComponentsHandHoldingEuroIcon
          v-else-if="cardData.color == 'pink'"
          class="icon"
        />
      </div>

      <div class="description-container">
        <div v-if="cardData.pricePerYear">
          <span class="price-per-year">{{ formatedPricePerYear }}</span>
          <span class="sub-description">{{ cardData.subdescription }}</span>
        </div>

        <div v-else-if="cardData.price">
          <span class="price">
            À partir de
            <span class="bolder">{{ formatedPrice }}</span>
          </span>
        </div>

        <div v-else-if="cardData.percent">
          <span class="percent">
            À partir de
            <span class="bolder">{{ formatedPercent }}</span>
          </span>
          <span class="sub-description">{{ cardData.subdescription }}</span>
        </div>

        <a
          class="read-more"
          :href="cardData.link"
          target="_blank"
        >
          En savoir plus
        </a>
      </div>
    </div>

    <div class="list-container">
      <ul class="list">
        <li
          v-for="(listData, index) in cardData.list"
          :key="index"
          class="list-item"
        >
          <Offers-ComponentsCheckIcon
            :class="cardData.color"
            class="check-icon"
          />
          <span class="text">{{ listData }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formatedPricePerYear() {
      return this.formatNumber(this.cardData.pricePerYear) + '€/an'
    },
    formatedPrice() {
      return this.formatNumber(this.cardData.price) + '€'
    },
    formatedPercent() {
      return this.formatNumber(this.cardData.percent) + '%'
    },
  },
  methods: {
    formatNumber(number) {
      return number.toString().replace('.', ',')
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  width: 381px;
  border: 1px solid var(--ds-color-gray-25);
  background-color: var(--ds-color-white-100);
  border-radius: 9px;
  display: grid;
  grid-template-rows: 98px 98px 1fr;

  .title-container {
    padding: 0 30px;
    display: flex;
    align-items: center;

    .title {
      margin: 0;
      font-size: var(--ds-font-md);
      font-weight: var(--ds-weight-semi-bold);
    }
  }

  .price-container {
    background-color: var(--ds-color-primary-25);
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 30px;

    .icon-container {
      .icon {
        height: 40px;
        transform: translateY(-25%);
      }
    }

    .description-container {
      .price-per-year {
        font-weight: var(--ds-weight-semi-bold);
        font-size: 20px;
      }

      .price,
      .percent {
        font-size: 14px;

        .bolder {
          font-size: 20px;
          font-weight: var(--ds-weight-semi-bold);
        }
      }

      .sub-description {
        color: var(--ds-color-gray-50);
        font-size: 12px;
        display: inline-block;
        margin-top: 2px;
        width: auto;
      }

      .read-more {
        text-decoration: none;
        display: block;
        margin-bottom: 16px;
        color: var(--ds-color-primary-100);
        font-weight: var(--ds-weight-semi-bold);
        font-size: var(--ds-font-lg);

        &:after {
          margin-left: 4px;
          content: '>';
          opacity: 0.4;
        }
      }
    }
  }

  .list-container {
    padding: 22px 30px;

    .list {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .list-item {
      display: flex;
      align-items: baseline;
      flex-shrink: 0;
      gap: 20px;
      font-weight: 500;
    }

    .check-icon {
      height: auto;
      width: 16px;

      &.orange :deep(path) {
        fill: #ffa257;
      }
      &.blue :deep(path) {
        fill: #374fc7;
      }
      &.pink :deep(path) {
        fill: #ff69ac;
      }
    }

    .text {
      font-size: 14px;
    }
  }

  @media @bp-desktop {
    .title-container {
      .title {
        font-size: 22px;
      }
    }

    .price-container {
      .icon-container {
        .icon {
          transform: none;
        }
      }

      .description-container {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        .price-per-year {
          font-size: 26px;
        }

        .price,
        .percent {
          font-size: 16px;

          .bolder {
            font-size: 22px;
          }
        }

        .read-more {
          flex-grow: 1;
          margin: 0;
          display: flex;
          margin-top: 4px;
        }
      }
    }

    .list-container {
      .text {
        font-size: 18px;
      }
    }
  }
}
</style>
