<template>
  <svg width="49" height="43" viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.4"
      d="M47.0313 18.8125H44.5529C43.8162 17.1461 42.7481 15.647 41.4135 14.4066L43 8.0625H40.3125C37.8434 8.0625 35.6605 9.19629 34.1816 10.9448C33.5459 10.8525 32.9119 10.75 32.25 10.75H21.5C13.5366 10.75 8.0625 17.3748 8.0625 24.1875C8.05923 28.4179 10.0513 32.4019 13.4375 34.9375V40.3125C13.4375 41.7968 14.6407 43 16.125 43H18.8125C20.2968 43 21.5 41.7968 21.5 40.3125V37.625H32.25V40.3125C32.25 41.7968 33.4532 43 34.9375 43H37.625C39.1093 43 40.3125 41.7968 40.3125 40.3125V34.8769C41.3054 34.1269 42.1894 33.2429 42.9395 32.25H47.0313C47.7733 32.25 48.375 31.6483 48.375 30.9062V20.1562C48.375 19.4142 47.7733 18.8125 47.0313 18.8125ZM36.2813 24.1875C35.5392 24.1875 34.9375 23.5858 34.9375 22.8438C34.9375 22.1017 35.5392 21.5 36.2813 21.5C37.0233 21.5 37.625 22.1017 37.625 22.8438C37.625 23.5858 37.0233 24.1875 36.2813 24.1875Z"
      fill="#374FC7"
    />
    <path
      d="M4.30505 21.4597C3.21426 21.2381 2.50954 20.174 2.73118 19.083C2.91762 18.1255 3.8129 17.4688 4.78712 17.4688H4.8711C5.14951 17.4688 5.37501 17.2432 5.37501 16.9649V15.2852C5.37501 15.0068 5.14951 14.7812 4.8711 14.7812C2.47755 14.7812 0.344342 16.4945 0.0395629 18.8645C0.0129398 19.066 -0.000329716 19.2687 6.22154e-06 19.4718C-0.00326917 22.0726 2.10222 24.1837 4.70313 24.1875H8.06251C8.06335 23.2843 8.15833 22.3836 8.34545 21.5H4.70313C4.56943 21.5 4.43606 21.4866 4.30505 21.4597ZM36.2813 21.5C35.5392 21.5 34.9375 22.1017 34.9375 22.8438C34.9375 23.5858 35.5392 24.1875 36.2813 24.1875C37.0233 24.1875 37.625 23.5858 37.625 22.8438C37.625 22.1017 37.0233 21.5 36.2813 21.5ZM28.4917 0.485514C27.61 0.16503 26.6793 0.000672162 25.7412 2.87222e-07C21.2884 -0.00117549 17.6779 3.60772 17.6768 8.06057C17.6765 9.11298 17.8823 10.1554 18.2826 11.1288C19.3362 10.8753 20.4163 10.7482 21.5 10.75H32.25C32.6069 10.7528 32.9635 10.7775 33.3175 10.824C34.8397 6.63645 32.6792 2.00773 28.4917 0.485514Z"
      fill="#374FC7"
    />
  </svg>
</template>
